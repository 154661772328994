<template>
    <div>
      <b-card>
        <b-row>
            <b-col sm="12" class="mt-2">
            <b> {{ $t('exportTrophyCircular.title') }} </b> : {{ currentLocale === 'bn' ? form.title_bn : form.title_en }}
            </b-col>
            <b-col sm="12" class="mt-2">
            <b> {{ $t('globalTrans.circular_type') }} </b> : {{ getCircularTypeName(form.circular_type) }}
            </b-col>
            <b-col sm="6" class="mt-2" v-if="form.circular_type === 2">
              <b> {{ $t('exportTrophyCircular.year')}} </b> : {{ EngBangNum(form.year.toString()) }}
            </b-col>
            <b-col sm="6" class="mt-2" v-if="form.circular_type === 1">
              <b> {{$t('externalUser.et')}} {{ $t('globalTrans.fiscal_year')}} </b> : {{ getFiscalYear(form.fiscal_year_id) }}
            </b-col>
            <b-col sm="6" class="mt-2">
              <b> {{ $t('exportTrophyCircular.memo_no')}} </b> : {{ form.memo_no }}
            </b-col>
            <b-col sm="6" class="mt-2">
              <b> {{ $t('exportTrophyCircular.circular_date') }} </b> : {{ form.date | dateFormat }}
            </b-col>
            <b-col sm="6" class="mt-2">
              <b> {{ $t('exportTrophyCircular.app_start_date')}} </b> : {{ form.app_start_date | dateFormat }}
            </b-col>
            <b-col sm="6" class="mt-2">
              <b> {{ $t('exportTrophyCircular.deadline')}} </b> : {{ form.deadline | dateFormat }}
            </b-col>
            <b-col sm="6" class="mt-2" v-if="id && form.attachment !== null">
              <a :href="exportTrophyCIPServiceBaseUrl + 'download-attachment?file=' + form.attachment" class="badge badge-primary">
                <i class="ri-cloud-fill"></i>
                {{ $t('globalTrans.attachment') }}
              </a>
            </b-col>
            <b-col sm="6" class="mt-2" v-else>
              {{ $t('globalTrans.attachment') }} : {{ $t('exportTrophyCircular.notGiven') }}
            </b-col>
            <b-col sm="12" class="mt-2">
                <p>
                  <b> {{ $t('globalTrans.description') }} : </b>
                </p>
                <div v-if="currentLocale === 'en'" v-html="form.description_en"></div>
                <div v-if="currentLocale === 'bn'" v-html="form.description_bn"></div>
            </b-col>
        </b-row>
      </b-card>
        <b-row>
          <b-col sm="12" class="mt-2">
            <p class="text-center">( {{ currentLocale === 'bn' ? form.contact_person_name_bn : form.contact_person_name_en }} )</p>
            <p class="text-center"> {{ getDesignation(form.designation_id) }} </p>
            <p class="text-center"> {{ getOrg(form.org_id) }} </p>
            <p class="text-center"> {{ $t('globalTrans.phone_no') }} - {{ EngBangNum(form.contact_person_mobile) }} </p>
            <p class="text-center"> {{ $t('globalTrans.email') }} : {{ form.contact_person_email }} </p>
          </b-col>
        </b-row>
        <b-row class="text-right">
          <b-col>
            <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-form-details')">{{ $t('globalTrans.cancel') }}</b-button>
          </b-col>
      </b-row>
    </div>
</template>
<script>
import { exportTrophyCIPServiceBaseUrl } from '@/config/api_config'
export default {
  name: 'Details',
  props: ['id', 'userDetails'],
  data () {
    return {
      exportTrophyCIPServiceBaseUrl: exportTrophyCIPServiceBaseUrl,
      valid: null,
      errors: [],
      userList: [],
      form: []
    }
  },
  created () {
    if (this.id) {
      const tmp = this.getCircular()
      this.form = tmp
      this.userList = this.userDetails
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    circularTypeList () {
      return [
        { value: 1, text_en: 'Export Trophy', text_bn: 'রপ্তানি ট্রফি', text: this.currentLocale === 'en' ? 'Export Trophy' : 'রপ্তানি ট্রফি' },
        { value: 2, text_en: 'CIP', text_bn: 'সিআইপি', text: this.currentLocale === 'en' ? 'CIP' : 'সিআইপি' }
      ]
    }
  },
  methods: {
    getCircular () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    getFiscalYear (fiscalYear) {
      const fiscalYearObj = this.$store.state.Portal.commonObj.fiscalYearList.find(fiscal => fiscal.value === parseInt(fiscalYear))
      if (fiscalYearObj !== 'undefined') {
        return this.currentLocale === 'bn' ? fiscalYearObj.text_bn : fiscalYearObj.text_en
      }
    },
    getOrg (orgId) {
      const orgObj = this.$store.state.Portal.commonObj.orgProfileList.find(org => org.value === parseInt(orgId))
      if (orgObj !== 'undefined') {
        return this.currentLocale === 'bn' ? orgObj.text_bn : orgObj.text_en
      }
    },
    getDesignation (designationId) {
      const designationObj = this.$store.state.Portal.commonObj.designationList.find(deg => deg.value === parseInt(designationId))
      if (designationObj !== 'undefined') {
        return this.currentLocale === 'bn' ? designationObj.text_bn : designationObj.text_en
      }
    },
    EngBangNum (n) {
      if (this.$i18n.locale === 'bn') {
        return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
      } else {
        return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
      }
    },
    getCircularTypeName (type) {
      const obj = this.circularTypeList.find(obj => obj.value === type)
      return this.currentLocale === 'en' ? obj.text_en : obj.text_bn
    }
  }
}
</script>
